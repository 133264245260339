body{
  background-color:#110C18;
  margin:0;
  font-family:'Poppins',sans-serif;
  font-weight:400;
  font-size:22px;
  color:white;
  line-height:1.5;
}
.fa{
  font-weight:800!important;
  font-size:27px!important;
}
.btn{
  font-size:22px;
  padding-left:30px;
  padding-right:30px;
  margin-top:5px;
}
.text-pop{
  font-family:'Poppins', sans-serif!important;
}
.navbar-spec{
  bottom: 0;
  border-bottom:2px solid rgba(66, 65, 65, 0.25);
  background-color:#110C18!important;
  box-shadow: -7px 3px 20px 0px #00000047;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 16px;
  padding-left: 16px;
  font-family:Montserrat;
  font-weight:500;
  transition:all 113.5s;
}
.nav-link:hover {
background: linear-gradient(180deg, #F8DFA6 0%, #F1BA2C 100%)!important;
    -webkit-background-clip: text!important;
    -webkit-text-fill-color: transparent!important;
    background-clip: text!important;
    text-fill-color: transparent!important;
}
.navbar-spec .nav-link{
  color:white!important;
}
.video{
  width:100%;
}
.logo{
  min-width:100px;
  max-width:150px;

}
.text-yell{
  color:#FDBD2C!important;
}
h1,h2,h3,h4,h5{
  margin-top:5px;
  margin-bottom:5px;
  font-weight:800;
  line-height:1.7;
  font-family:'Montserrat',sans-serif;
}
p{
  margin-top:5px;
  margin-bottom:5px;
  line-height:2;
}
h1{
  font-size:30pt;
}
h2{
  font-size:28pt;
}
h3{
  font-size:26pt;
}
h4{
  font-size:24pt;
}
h5{
  font-size:22pt;
}
h6{
  font-size:20pt;
}
@media(min-width:992px){
.header{
  margin-top:100px;
}
}
.divider{
  margin-bottom:10px;
  margin-top:10px;
}
.btn-feez{
  
  border: 5px solid #F2B11C;
  border-radius: 10px;
  font-weight:700;
}
.btn-feez-filled{
  background-color:#FDBD2C;
  color:#1A1B1E!important;
  border: 5px solid #F2B11C;
  border-radius: 10px;
  font-weight:700;
  transition:0.3s ease-in-out;
}
.btn-feez-filled:hover{
  background-color:#FDBD2C;
  color:#1A1B1E!important;
  border: 5px solid #F2B11C;
  border-radius: 10px;
  font-weight:700;
  transform:scaleY(1.1);
}
.screen-img{
  width:100%;
}

.screen{
  background:url('./img/el-1.png');
  background-size:100%;
  float:right;
  width:500px;
  height:100%;
  position:absolute;
 right:0;
 top:0;
}
.accordion-button{
  background-color: #0F0B15!important;
  color:white!important;
  font-family:'Montserrat',sans-serif!important;
  font-size:13pt!important;
  font-weight:800;
}
.program{
  margin-top:150px;
}
.info{
  margin-top:150px;
background: linear-gradient(180deg, rgba(0, 0, 0, 0.064) 0%, rgba(253, 254, 254, 0.04) 100%);
backdrop-filter: blur(4.66667px);
border-radius: 7.77778px;
}
.card-yell{
  padding:5px;
  width:100%;
  height:350px;
  border-radius:17px;
 background-image: 
    linear-gradient(
      #FFF0CF85, 
      #FDBD2C85
    );
}
.card-yell-text{
  background-color:#110C18;
  padding-top:45px;
  padding-bottom:45px;
  padding-right:45px;
  padding-left:70px;
  height:100%;
  border-radius:17px;
}
.text-grad{
  color: linear-gradient(180deg, #F8DFA6 0%, #F1BA2C 100%);
}

.text-300{
  font-weight:300!important;
}
.text-400{
  font-weight:400!important;
}
.text-500{
  font-weight:500!important;
}
.text-600{
  font-weight:600!important;
}
.text-700{
  font-weight:700!important;
}
.text-800{
  font-weight:800!important;
}
.text-title-program{
  font-size:35pt;
  background: linear-gradient(180deg, #F8DFA6 0%, #F1BA2C 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}

.fasilitas{
  margin-top:70px;
  padding:60px;
  padding-left:170px;
}

.img-discord{
  width:50px;
}

.teacher{
  margin-top:70px;
}

.latest{
  margin-top:100px;
  background: url('./img/bg-latest.png');
  background-size: cover;
  padding: 30px;
  border-top: 5px solid #CE9B18;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
}

.card-glass{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.064) 0%, rgba(253, 254, 254, 0.027) 100%);
  backdrop-filter: blur(5.66667px);
  border-radius: 7.77778px;
  width: 100%;
  padding: 35px;
  border: 1px solid #CE9B18;
  color: white;
}

.list-check{
  list-style:none;
}
.list-check li::before{
  content:url('./img/check.svg');
  display: inline-block;
  margin-left: -1.3em; /* same as padding-left set on li */
  margin-top:4px;
  width: 1.3em; /* same as padding-left set on li */
}

.text-yellg{
  color:#FFC542!important;
}

.title-latest{
  font-size:40pt;
}

.bef{
  width:300px;
}
.text-ijo{
  color:#36EF48!important;
}
.label-program{
  background-color:#FDBD2C;
  width:fit-content;
  padding:10px;
  color:#0F0B15;
  font-family:'Montserrat',sans-serif;
  font-size:27pt;
  font-weight:800;
  position:absolute;
  margin-top:70px;
  margin-left:300px;
}

.testimoni{
  margin-top:100px;
}
.mobile-display{
  display:none;
}
.accordion-body{
  font-size:14pt;
}
.desktop-display{
  display:display;
}
@media(max-width:780px){
  .bef {
    width: 200px;
}
.label-program {
  background-color: #FDBD2C;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  color: #0F0B15;
  font-family: 'Montserrat',sans-serif;
  font-size: 18pt;
  font-weight: 800;
  position: absolute;
  margin-top: 70px;
  margin-left: 50px;
}
  .screen {
     background-size: cover;
    float: right;
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
}
  .info,.program,.fasilitas,.teacher{
    margin-top:50px;
  }
.mobile-display{
    display:block!important;
  }  
  .desktop-display{
    display:none;
  }

  h1,h2,h3,h4,h5,h6,.text-title-program{
    font-size:20pt!important;
  }
  p{
    font-size:16pt;
  }
  .col,.col-2,.col-4, .col-6, .col-8, .col-10, .col-12{
    width:100%!important;
    text-align:center;
    flex:none!important;
  }

  .container, .fasilitas, .info, .program{
          padding:20px;
  }
  .card-yell-text{
    padding:20px!important;
   
  }
  .card-yell{
    margin-top:20px;
  }

 
}


.site-footer
{
  background-color: #0F0B15;
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  border-top: 2px solid rgba(66, 65, 65, 0.25);
}
.site-footer hr
{
  border-top-color:#bbb;
  opacity:0.5
}
.site-footer hr.small
{
  margin:20px 0
}
.site-footer h6
{
  color:#fff;
  font-size:16px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px
}
.site-footer a
{
  color:#737373;
}
.site-footer a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links
{
  padding-left:0;
  list-style:none
}
.footer-links li
{
  display:block
}
.footer-links a
{
  color:#737373
}
.footer-links a:active,.footer-links a:focus,.footer-links a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links.inline li
{
  display:inline-block
}
.site-footer .social-icons
{
  text-align:right
}
.site-footer .social-icons a
{
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:#33353d
}
.copyright-text
{
  margin:0
}
@media (max-width:991px)
{
  .site-footer [class^=col-]
  {
    margin-bottom:30px
  }
}
@media (max-width:767px)
{
  .site-footer
  {
    padding-bottom:0
  }
  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:center
  }
}
.social-icons
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icons li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#96a2b2;
  font-weight:700;
  font-size:13px
}
.social-icons a{
  background-color:#eceeef;
  color:#818a91;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
  color:#fff;
  background-color:#29aafe
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}
.social-icons a.facebook:hover
{
  background-color:#3b5998
}
.social-icons a.twitter:hover
{
  background-color:#00aced
}
.social-icons a.linkedin:hover
{
  background-color:#007bb6
}
.social-icons a.dribbble:hover
{
  background-color:#ea4c89
}
@media (max-width:767px)
{
  .social-icons li.title
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
}




.shine {
  
  -webkit-background-clip: text;
  -webkit-animation-name: shine;
  -webkit-animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  
}

.chrome {
  background: #222 -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff)) 0 0 no-repeat;
  background-image: -webkit-linear-gradient(-40deg, transparent 0%, transparent 40%, #fff 50%, transparent 60%, transparent 100%);
  -webkit-background-size: 200px;
  color: rgba(255, 255, 255, 0.3);
  -webkit-background-clip: text;
  -webkit-animation-name: shine;
  -webkit-animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  text-shadow: 0 0px 0px rgba(255, 255, 255, 0.5);
}

@-webkit-keyframes shine {
  0%, 10% {
    background-position: -1000px;
  }
  20% {
    background-position: top left;
  }
  90% {
    background-position: top right;
  }
  100% {
    background-position: 1000px;
  }
}


.btn-join{
  position:absolute;
  animation:disp 10s infinite;
  animation-delay:10s;
}


.btn-hove{
  animation:hove 3s infinite;
  
}

@keyframes disp{
  0%{
    display:none!important;
    width:0px;
    height:0px;
    content: url('./img/click.gif');
  }
  100%{
    display:block!important;
    width:30px;
    height:30px;
    content: url('./img/click.gif');
  }
}
@keyframes hove{
  0%{
    position: relative;
  cursor:pointer;
  user-select:none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s; /* Safari */
}
  
  50%{
    position: relative;
  cursor:pointer;
  user-select:none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition: all 0.5s;
  box-shadow: 0 0 1px 4px #fdda2c59;
  }
  100%{
    position: relative;
  cursor:pointer;
  user-select:none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s; /* Safari */

  transition: all 0.5s;
  box-shadow: 7px 4px 20px 14px #eec9107a;
  }
 

}